@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
@font-face {
  font-family: "Lufga";
  font-display: swap;
  src: url("./assets/fonts/font.woff");
}
body {
  margin: 0;
  font-family: "Quicksand";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
