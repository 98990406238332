.footer {
  @mixin flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .top {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    @include flex();
    a {
      margin: 0 2rem;
    }
  }
  .divider {
    height: 10px;
    background: #00aaff;
  }
  .bottom {
    position: relative;
    padding: .75rem;
    @include flex();
    background: #292929;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    ul {
      margin-left: 0;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      li {
        display: inline-block;
        margin: 0 1rem;
        a {
          text-decoration: none;
          font-size: 18px;
          line-height: 22px;
          color: #ffffff;
        }
      }
    }
    .copyright {
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
      position: absolute;
      right: 2px;
      bottom: 2px;
    }
  }
}
@media (min-width: 64em) {
  .footer {
    .bottom {
      ul {
        li {
          margin: 0 3rem;
        }
      }
    }
  }
}
