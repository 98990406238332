.landing__ {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  main {
    flex-grow: 1;
    .content {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      padding: 1rem 0;
    }
  }
}
.landing__text__info {
  padding: 2rem;
  h1 {
    font-size: 40px;
    max-width: 15ch;
    font-family: "Lufga";
    margin: 1.5rem 0;
    color: #000000;
    text-transform: uppercase;
  }
  p {
    font-size: 20px;
    line-height: 25px;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #292929;
  }
  ul {
    padding-left: 1rem;
    margin-bottom: 1.5rem;
    li {
      font-size: 20px;
      line-height: 25px;
      color: #292929;
      letter-spacing: -0.03em;
      margin: 1.5rem 0;
    }
  }
  .download {
    margin-top: 1.5rem;
  }
}
.landing__video__placeholder {
  padding: 2rem;
  .image__placeholder {
    img {
      content: url("../../assets/images/phone.png");
      object-fit: contain;
      //max-width: 100%;
      max-width: 360px;
      border-width: .5px;
      border-color: rgb(165, 165, 165);
      border-style: solid;
      border-radius: 30px;
      box-shadow: 5px 5px 10px #ccc;
      
    }
  }
}
@media (min-width: 64em) {
  .landing__text__info {
    h1 {
      font-size: 55px;
      line-height: 72px;
    }
    .download {
      min-width: 177px;
    }
  }
  .landing__video__placeholder {
    padding: unset;
  }
  .landing__ {
    main {
      .content {
        max-width: 95%;
        margin: 1rem auto;
        justify-content: center;
        gap: 6%;
      }
    }
  }
}
